<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>产品管理</el-breadcrumb-item>
      <el-breadcrumb-item>订单管理</el-breadcrumb-item>
      <el-breadcrumb-item>订单详情</el-breadcrumb-item>
    </el-breadcrumb>
    <el-page-header @back="goBack"></el-page-header>
    <!-- 订单信息 -->
    <el-card style="margin-top: 15px">
      <el-descriptions title="订单信息">
        <el-descriptions-item
          label="订单编号"
          :contentStyle="{ color: 'orange' }"
          >{{ orderData.orderInfo.orderNo }}</el-descriptions-item
        >
        <el-descriptions-item label="产品名称">{{
          orderData.orderInfo.orderName
        }}</el-descriptions-item>
        <el-descriptions-item label="支付类型">
          <template>
            <el-tag
              type="danger"
              v-if="orderData.orderInfo.orderSource === 1"
              size="small"
              >小程序</el-tag
            >
            <el-tag
              type="danger"
              v-else-if="orderData.orderInfo.orderSource === 2"
              size="small"
              >网站</el-tag
            >
            <el-tag
              type="danger"
              v-else-if="orderData.orderInfo.orderSource === 3"
              size="small"
              >线下</el-tag
            >
            <el-tag
              type="danger"
              v-else-if="orderData.orderInfo.orderSource === 4"
              size="small"
              >表单大师</el-tag
            >
            <span v-else>-</span>
          </template>
        </el-descriptions-item>
        <el-descriptions-item label="订单金额"
          >￥{{ orderData.orderInfo.orderAmount }}</el-descriptions-item
        >
        <el-descriptions-item label="优惠金额"
          >￥{{ orderData.orderInfo.discountAmount }}</el-descriptions-item
        >
        <el-descriptions-item
          label="实际支付金额"
          :contentStyle="{ color: 'red' }"
          >￥{{ orderData.orderInfo.paymentAmount }}</el-descriptions-item
        >
        <el-descriptions-item label="下单时间">{{
          orderData.orderInfo.orderTime
        }}</el-descriptions-item>
        <!-- <el-descriptions-item label="是否寄送">
          <template>
            <el-tag
              type="danger"
              v-if="orderData.orderInfo.isSend === 0"
              size="mini"
              >无需寄送</el-tag
            >
            <el-tag
              type="success"
              v-else-if="orderData.orderInfo.isSend === 1"
              size="mini"
              >需要寄送</el-tag
            >
            <span v-else>-</span>
          </template>
        </el-descriptions-item> -->
        <el-descriptions-item label="订单状态">
          <template>
            <el-tag v-if="findStatus(orderData.orderInfo.orderStatus)" :type="findStatus(orderData.orderInfo.orderStatus).type" size="mini">{{ findStatus(orderData.orderInfo.orderStatus).dictLabel }}</el-tag>

            <!-- <el-tag
              type="warning"
              v-if="orderData.orderInfo.orderStatus === 1"
              size="small"
              >待支付</el-tag
            >
            <el-tag
              type="warning"
              v-else-if="orderData.orderInfo.orderStatus === 2"
              size="small"
              >待发货/已支付</el-tag
            >
            <el-tag
              type="success"
              v-else-if="orderData.orderInfo.orderStatus === 3"
              size="small"
              >已发货</el-tag
            >
            <el-tag
              type="success"
              v-else-if="orderData.orderInfo.orderStatus === 4"
              size="small"
              >订单完成</el-tag
            >
            <el-tag
              type="danger"
              v-else-if="orderData.orderInfo.orderStatus === 5"
              size="small"
              >申请退款</el-tag
            >
            <el-tag
              type="warning"
              v-else-if="orderData.orderInfo.orderStatus === 6"
              size="small"
              >退款审核中</el-tag
            >
            <el-tag
              type="success"
              v-else-if="orderData.orderInfo.orderStatus === 7"
              size="small"
              >退款成功</el-tag
            >
            <el-tag
              type="danger"
              v-else-if="orderData.orderInfo.orderStatus === 8"
              size="small"
              >退款失败</el-tag
            > -->
            <span v-else>-</span>
          </template>
        </el-descriptions-item>
        <el-descriptions-item label="备注">{{
          orderData.orderInfo.remark
        }}</el-descriptions-item>
      </el-descriptions>
    </el-card>
    <!-- 支付信息 -->
    <el-card style="margin-top: 15px">
      <el-descriptions title="支付信息">
        <el-descriptions-item
          label="订单编号"
          :contentStyle="{ color: 'orange' }"
          >{{ orderData.tbOrderPay.orderNo }}</el-descriptions-item
        >
        <el-descriptions-item label="商户类型">
          <template>
            <el-tag type="warning" size="mini" v-if="orderData.tbOrderPay.merchantType === 1">天津</el-tag>
            <el-tag type="warning" size="mini" v-if="orderData.tbOrderPay.merchantType === 2">北京</el-tag>
          </template>
        </el-descriptions-item>
        <el-descriptions-item label="支付平台">
          <template>
            <el-tag type="success" size="mini">{{
              orderData.tbOrderPay.payPlatform
            }}</el-tag>
          </template>
        </el-descriptions-item>
        <el-descriptions-item label="支付类型">{{
          orderData.tbOrderPay.payType
        }}</el-descriptions-item>
        <el-descriptions-item label="支付金额"
          >￥{{ orderData.tbOrderPay.payPrice }}</el-descriptions-item
        >
        <el-descriptions-item label="IP地址">{{
          orderData.tbOrderPay.ip
        }}</el-descriptions-item>
        <el-descriptions-item label="支付状态">
          <template>
            <el-tag
              type="danger"
              v-if="orderData.tbOrderPay.payStatus === 1"
              size="small"
              >失败</el-tag
            >
            <el-tag
              type="success"
              v-else-if="orderData.tbOrderPay.payStatus === 2"
              size="small"
              >成功</el-tag
            >
          </template>
        </el-descriptions-item>
        <el-descriptions-item label="支付时间">{{
          orderData.tbOrderPay.payTime
        }}</el-descriptions-item>
        <el-descriptions-item label="微信订单">{{
          orderData.tbOrderPay.transactionId
        }}</el-descriptions-item>
      </el-descriptions>
    </el-card>
    <!-- 收件人信息 -->
    <el-card v-if="orderData.orderAddress" style="margin-top: 15px">
      <el-descriptions title="收件人信息">
        <el-descriptions-item label="收件人">{{
          orderData.orderAddress.receiptName
        }}</el-descriptions-item>
        <el-descriptions-item label="联系方式">{{
          orderData.orderAddress.receiptPhone
        }}</el-descriptions-item>
        <el-descriptions-item label="收货地址"
          >{{ orderData.orderAddress.province }}/{{
            orderData.orderAddress.city
          }}/{{ orderData.orderAddress.area }}</el-descriptions-item
        >
        <el-descriptions-item label="详细地址">{{
          orderData.orderAddress.receiptAddress
        }}</el-descriptions-item>
      </el-descriptions>
    </el-card>
    <!-- 产品信息 -->
    <el-card style="margin-top: 15px">
      <el-row class="title_row" align="middle" type="flex">
        <el-col :span="9">
          <span class="product_title">产品信息</span>
        </el-col>
      </el-row>
      <el-table
        header-cell-class-name="tableHeaderStyle"
        :data="tbOrderProductList"
        border
        stripe
      >
        <el-table-column label="序号" type="index"></el-table-column>
        <el-table-column label="赛季" prop="season"></el-table-column>
        <el-table-column label="商品名称" prop="productName"> </el-table-column>
        <el-table-column label="商品数量" prop="productNum"> </el-table-column>
        <el-table-column label="商品单价" prop="unitPrice">
          <template slot-scope="scope">
            <span>￥{{ scope.row.unitPrice }}</span>
          </template>
        </el-table-column>
        <el-table-column label="商品总价" prop="totalAmount">
          <template slot-scope="scope">
            <span>￥{{ scope.row.totalAmount }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="处理方式"
          prop="handType"
          :formatter="handTypeFormatter"
        ></el-table-column>
      </el-table>
    </el-card>
    <!-- 物流信息 -->
    <el-card v-if="orderData.tbOrderExpress" style="margin-top: 15px">
      <el-row class="title_row" align="middle" type="flex">
        <el-col :span="9">
          <span class="product_title">物流信息</span>
        </el-col>
      </el-row>
      <el-table
        header-cell-class-name="tableHeaderStyle"
        :data="[orderData.tbOrderExpress]"
        border
        stripe
      >
        <el-table-column label="序号" type="index"></el-table-column>
        <el-table-column label="物流单号" prop="expressNumber"> </el-table-column>
        <el-table-column
          label="物流公司"
          prop="expressType"
          :formatter="expressFormatter"
        ></el-table-column>
        <el-table-column label="订单号" prop="orderNo"></el-table-column>
        <el-table-column label="类型" prop="type">
          <el-tag type="success" size="mini">发货</el-tag>
        </el-table-column>
        <el-table-column label="操作" v-if="orderData.orderInfo.orderStatus === 3">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="changeFn(scope.row)">修改物流信息</el-button>
            <el-button type="text" size="small" @click="showLogistics">查看物流</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <!-- 查看物流详情 -->
    <el-dialog
      title="物流信息"
      :visible.sync="logisticsVisible"
      @close="hidelLgistics"
      width="30%"
    >
      <div class="box">
        <div class="title">顺丰快递 <el-tag type="success" size="mini">发货</el-tag> <el-tag type="danger" size="mini">退货</el-tag></div>
        <div class="num">物流单号：123123213213232312</div>
      </div>
      <div class="detail">
        <div class="item" v-for="item in list" :key="item">
          <div class="disc"></div>
          <div class="status"><span>运输中</span>2020-10-10 12:00:00</div>
          <div class="desc">快件已到达【天津】，即将派送。快件已到达【天津】，即将派送。快件已到达【天津】，即将派送。快件已到达【天津】，即将派送。快件已到达【天津】，即将派送。</div>
        </div>
      </div>
    </el-dialog>
    <!-- 填写快递信息弹窗 -->
    <el-dialog
      title="快递信息"
      :visible.sync="dialogVisible"
      @close="handleToClose"
      width="30%"
    >
      <el-form
        :model="sendProductForm"
        label-width="100px"
        ref="sendProductFormRef"
        :rules="sendProductInfoRules"
        style="width: 100%"
      >
        <el-form-item label="快递公司" prop="expressType">
          <el-select
            placeholder="请选择"
            v-model="sendProductForm.expressType"
            style="width: 100%"
          >
            <el-option
              v-for="item in dict_express"
              :key="item.dictValue"
              :label="item.dictLabel"
              :value="item.dictValue"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="快递单号" prop="expressNumber">
          <el-input
            type="textarea"
            v-model="sendProductForm.expressNumber"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="subSendProductMessage"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getTbOrderInfo, fillInTheTrackingNumber } from '@/http/api'

export default {
  data () {
    return {
      dialogVisible: false,
      sendProductForm: {
        expressType: '',
        expressNumber: ''
      },
      sendProductInfoRules: {
        expressType: [{ required: true, message: '请选择', trigger: 'change' }],
        expressNumber: [{ required: true, message: '请输入', trigger: 'blur' }]
      },
      dict_express: this.$userInfo.getDataList(this, 'express_type').then(value => { this.dict_express = value }),
      dict_handType: this.$userInfo.getDataList(this, 'p_hand_type').then(value => { this.dict_handType = value }),
      orderData: {
        orderInfo: {},
        orderAddress: {},
        tbOrderPay: {}
      },
      tbOrderProductList: [],
      order_no: this.$route.query.order_no,
      userId: this.$route.query.user_id,
      logisticsVisible: false,
      list: [1, 1, 1],
      order_status: this.$userInfo.orderStatus()
    }
  },
  created () {
    sessionStorage.setItem('order_no', JSON.stringify(this.order_no))
    this.getTbOrderInfo()
  },
  methods: {
    findStatus (status) {
      return this.order_status.find(item => item.dictCode === status)
    },
    // 获取订单详情
    getTbOrderInfo () {
      getTbOrderInfo({ orderNo: JSON.parse(sessionStorage.getItem('order_no')), userId: this.userId }).then((res) => {
        console.log('getTbOrderInfo', res)
        this.$nextTick(() => {
          this.orderData = res.data
          this.tbOrderProductList = res.data.tbOrderProduct
        })
      }).catch((err) => {
        console.log('err', err)
      })
    },
    handTypeFormatter (data) {
      var temp = '未设置'
      for (const key in this.dict_handType) {
        if (Object.hasOwnProperty.call(this.dict_handType, key)) {
          const element = this.dict_handType[key]
          if (element.dictValue === data.handType + '') {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    expressFormatter (data) {
      var temp = '未设置'
      for (const key in this.dict_express) {
        if (Object.hasOwnProperty.call(this.dict_express, key)) {
          const element = this.dict_express[key]
          if (element.dictValue === data.expressType + '') {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    changeFn (row) {
      const { id, userId, orderNo } = row
      this.sendProductForm = { ...{ id, userId, orderNo }, ...this.sendProductForm }
      this.dialogVisible = true
    },
    handleToClose () {

    },

    showLogistics () {
      this.logisticsVisible = true
    },
    hidelLgistics () {
    },
    // 提交快递信息
    subSendProductMessage () {
      this.$refs.sendProductFormRef.validate(valid => {
        if (!valid) return
        fillInTheTrackingNumber(this.sendProductForm).then((res) => {
          // console.log('fillInTheTrackingNumber', res)
          this.dialogVisible = false
          this.$message.success('操作成功')
          this.getTbOrderInfo()
        }).catch((err) => {
          console.log('err', err)
        })
      })
    },
    goBack () {
      this.$router.back()
    }
  }
}
</script>

<style lang="less" scoped>
.product_title {
  display: -moz-inline-box;
  display: inline-block;
  width: 120px;
  color: #303133;
  font-size: 16px;
  font-weight: 700;
}
.box{
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
  margin-bottom: 20px;
  color: #333;
  .title{
    font-size: 16px;
    margin-bottom: 10px;
  }
}
.detail{
  padding-left: 10px;
  .item{
    padding-left: 18px;
    border-left: 1px solid #aeaeae;
    position: relative;
    &:last-child{
      border-left: none;
    }
    .disc{
      width: 8px;
      height: 8px;
      background-color: #aeaeae;
      border-radius: 8px;
      position: absolute;
      left: -4px;
      top: 0;
    }
    .status{
      position: relative;
      top: -6px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      color: #999;
       span{
        color: #333;
        font-size: 14px;
        font-weight: 500;
       }
    }
    .desc{
      font-size: 12px;
      padding-bottom: 32px;
    }
    &:first-child{
      .status{
        span{
          color: #409EFF;
        }
      }
      .disc{
        background-color: #409EFF;
        width: 12px;
        height: 12px;
        border-radius: 12px;
        left: -6px;
      }
    }
  }
}
</style>
